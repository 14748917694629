/**
 * Slider  Tool
 *
 * @component Lightbox
 */

import Glide from '@glidejs/glide';

class Slider {

    constructor() {
        const sliders = document.querySelectorAll('[data-slider]');
        if (sliders.length === 0 ) return;
        
        let options = {
            type: 'carousel',
            startAt: 0,
            perView: 1
        };
        sliders.forEach(slider => {
            if (slider.querySelector('.glide__slide')) {
                if (slider.dataset.slider == 'partnerlogos') {
                    options.perView = 2;
                    options.breakpoints = {
                        900: {
                            perView: 1
                        }
                    };
                }
                new Glide(slider, options).mount();
            }
        })
    }
}

export default Slider;
