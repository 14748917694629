/**
 * main.js
 *
 * @version 1.0.0
 * @copyright 2018 SEDA.digital GmbH & Co. KG
 */

//Imports
import MobileNav from './components/_mobile-nav.js';
new MobileNav();

import ToggleTable from './components/_toggleTable.js';
new ToggleTable();

import ToggleList from './components/_toggleList.js';
new ToggleList();

import PureCounter from '@srexi/purecounterjs';
new PureCounter();

import BaugebieteFilter from './components/_baugebiet-filter';
new BaugebieteFilter();

import SVGmap from './components/_svgmap.js';
new SVGmap();

import Slider from './components/_slider.js';
new Slider();

import Lightbox from './components/_lightbox.js';
new Lightbox();

import Toolbar from './components/_toolbar.js';
new Toolbar();

import ParzellenButton from './components/_parzellenButton';
new ParzellenButton();
