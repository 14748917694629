/**
 * All JavaScript required for parzellenButton component
 *
 * @component parzellenButton
 */

 class ParzellenButton {

    constructor() {
        let self = this;
        self.buttonFunction();
    }

    buttonFunction() {
        const buttons = document.querySelectorAll("[data-lotnumber]");
        if (buttons.length === 0) {
            return;
        }
        const lotNumberField = document.forms['form'].elements["plotnumber"]; 

        buttons.forEach((button) => {
            button.addEventListener("click", () => {
                lotNumberField.value = button.dataset.lotnumber;
            })
        })
    }
}

export default ParzellenButton;
