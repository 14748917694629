/**
 * All JavaScript required for toolbar
 *
 * @component toolbar
 */

class Toolbar {

    constructor() {
        let self = this;
        self.toolbarFunction();
    }

    toolbarFunction() {
        const toolBar = document.querySelector("[data-toolbar]");
        if (!toolBar) return;
        const toggleIcon = document.querySelector(".toggle-icon");
        const toggleList = document.querySelector("[data-toggleToolbar]");

        toggleIcon.addEventListener("click", () => {
            toggleIcon.classList.toggle("is-expanded")
            if (toggleIcon.classList.contains("is-expanded")) {
                toggleList.classList.remove("show-for-medium");
                toggleList.classList.remove("animation-hidden");
                toggleList.classList.add("animation-hidden-undo");
            } else {
                toggleList.classList.add("animation-hidden");
                toggleList.classList.remove("animation-hidden-undo");
            }
        })
    }
}

export default Toolbar;
