/**
 * All JavaScript required for tables
 *
 * @component table
 */

class ToggleTable {

    constructor() {
        let self = this;
        self.toggleTableFunction();
    }

    toggleTableFunction() {
        const toggleTables = document.querySelectorAll('[data-toggleTable]');
        if (toggleTables.length === 0) {
            return;
        }

    
        toggleTables.forEach((toggleTable) => {
            const rows = Array.from(toggleTable.querySelectorAll('[data-toggleItem]'));
            const button = toggleTable.querySelector('[data-toggleButton]');
            const buttonLabel = button.querySelector("span");
            let numOfShownItems = toggleTable.dataset.showntoggleitems;

            if (numOfShownItems >= rows.length) {
                button.classList.add("u-visually-hidden");
            } else {         
                const hiddenRows = rows.slice(numOfShownItems);
                hiddenRows.forEach(row => row.classList.add("u-row-hidden"));

                button.addEventListener("click", (e) => {
                    e.preventDefault();
                    if (buttonLabel.innerHTML == "mehr anzeigen") {

                        hiddenRows.forEach((row) => {
                            row.classList.remove("u-row-hidden");
                            row.classList.add("u-row-hidden-undo");
                        });
                        buttonLabel.innerHTML = "weniger anzeigen";
                    } else {
                        hiddenRows.forEach((row) => {
                            row.classList.add("u-row-hidden");
                            row.classList.remove("u-row-hidden-undo");
                        });
                        buttonLabel.innerHTML = "mehr anzeigen";
                    }
                });
            }
        })
    }
}

export default ToggleTable;
