/**
 * SVG Map Component
 *
 * @component SVGmap
 */

class SVGmap {

    constructor() {
        let self = this;
        
        // Config vars
        self.offsetX = 0;
        self.offsetY = 0;
        self.smallMediaQuery = '(max-width: 640px)';

        self.init();
    }

    init () {
        let self = this;
        const svgmap = document.querySelector("[data-svgmap]");
        if (svgmap === null) {
            return;
        }

        const infobox = document.querySelector("[data-svgmap-infobox]");
        const infoboxContent = document.querySelector("[data-svgmap-infobox-content]");
        const items = svgmap.querySelectorAll('.has-result');

        self.addEvents(svgmap, infobox, infoboxContent, items);
        self.closeBox(infobox, infoboxContent, items);

    }

    addEvents (svgmap, infobox, infoboxContent, items) {
        let self = this;
        
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
        const mqList = window.matchMedia(self.smallMediaQuery);

        items.forEach(item => {
            const name = item.dataset.name;
            const count = item.dataset.count;
            const link = item.dataset.link;

            item.addEventListener('click', (e) => {
                
                // Reset
                infobox.style.display = 'none';
                self.removeActiveClasses(items);
                
                // Creating content
                infoboxContent.innerHTML = self.createInfobox(name, count, link);
                
                // Positioning
                const coords = self.getCursorPosition(svgmap, e);
                infobox.style.top = (coords[1] + self.offsetX) + 'px';
                
                if (mqList.matches) {
                    infobox.style.left = '20px';
                } else {
                    infobox.style.left = (coords[0] + self.offsetY) + 'px';
                }
                
                // Visibility
                infobox.style.display = 'block';
                item.classList.add('is-active');

            })
        })
    }

    closeBox (infobox, infoboxContent, items) {
        let self = this;
         
        const closeButton = infobox.querySelector('[data-svgmap-infobox-close]');

        closeButton.addEventListener('click', () => {
            infoboxContent.innerHTML = '';
            infobox.style.display = 'none';
            self.removeActiveClasses(items);
        })
    }

    createInfobox (name, count, link) {
        const template = `<p><a href="${link}" title="${name}"><span>${name} (${count})</span></a></p>`;
        return template;
    }
    
    removeActiveClasses (items) {
         items.forEach(item => {
             item.classList.remove('is-active');
         })
    }

    getCursorPosition(el, event) {
        const rect = el.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        return [x,y];
    }
}

export default SVGmap;
