/**
 * All JavaScript required for filtering on the baugebiet overview page
 *
 * @component baugebiet-filter
 */

class BaugebieteFilter {

    constructor() {
        let self = this;
        self.initfilter();
    }

    initfilter() {
        const filter = document.getElementById("region");
        if (!filter) return;
        const regions = [...document.querySelectorAll(".c-results__item")].map(i => i.dataset.region);
        const uniqueRegions = [...new Set(regions)];

        uniqueRegions.forEach((region) => {
            let option = document.createElement("option");
            let words = region.replace(/\-/g, " ").split(" ");
            words = words.map((word) => { 
                return word[0].toUpperCase() + word.substring(1); 
            }).join(" ");
            option.text = words;
            option.value = region;
            filter.add(option);
        });

        this.filterfunction();
    }

    filterfunction() {
        const filter = document.getElementById("region");
        const items = document.querySelectorAll(".c-results__item");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // check if there's a region as urlParam
        let regionParam = urlParams.get('region');
        if (regionParam) {
            regionParam = regionParam.replace(/\s+/g, '-').toLowerCase();

            // change shown filter to urlParam
            let option;
            for (let i = 0; i < filter.options.length; i++) {
                option = filter.options[i];
                
                if (option.value == regionParam) {
                    option.setAttribute('selected', true);
                } 
            }

            // filter items
            items.forEach((item) => {
            const itemRegion = item.dataset.region;
                if (itemRegion != regionParam ) {
                    item.classList.add("u-visually-hidden");
                } else {
                    item.classList.remove("u-visually-hidden");
                }
            })
        }

        filter.addEventListener("change", function () {
            let filterValue = filter.value;
            items.forEach((item) => {
                if (filterValue === "") {
                    item.classList.remove("u-visually-hidden");
                } else {
                    const itemRegion = item.dataset.region;
                    if (itemRegion != filterValue ) {
                        item.classList.add("u-visually-hidden");
                    } else {
                        item.classList.remove("u-visually-hidden");
                    }
                }
            });
        });
    }
}

export default BaugebieteFilter;
