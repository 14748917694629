/**
 * Lightbox JS Tool
 *
 * @component Lightbox
 */

import GLightbox from 'glightbox';

class Lightbox {

    constructor() {
        let self = this;
        if (document.querySelector('[data-lightbox]') === null) {
            return;
        }

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
        });
    }
}

export default Lightbox;
