/**
 * All JavaScript required for projectstatus component
 *
 * @component projectstatus
 */

 class ToggleList {

    constructor() {
        let self = this;
        self.toggleListFunction();
    }

    toggleListFunction() {
        const toggleLists = document.querySelectorAll('[data-toggleList]');
        if (toggleLists.length === 0) {
            return;
        }

        toggleLists.forEach((toggleList) => {
            const items = Array.from(toggleList.querySelectorAll('[data-toggleItem]'));
            const button = toggleList.querySelector('[data-toggleButton]');
            const buttonLabel = button.querySelector("span");
            let numOfShownItems = toggleList.dataset.showntoggleitems;

            if (numOfShownItems >= items.length) {
                button.classList.add("u-visually-hidden");
            } else {         

                const hiddenItems= items.slice(numOfShownItems);
                hiddenItems.forEach(item => item.classList.add("u-visually-hidden"));

                button.addEventListener("click", (e) => {
                    e.preventDefault();
                    if (buttonLabel.innerHTML == "mehr anzeigen") {
                        hiddenItems.forEach((item) => {
                            item.classList.remove("u-visually-hidden");
                            item.classList.add("u-visually-hidden-undo");
                        });
                        buttonLabel.innerHTML = "weniger anzeigen";
                    } else {
                        hiddenItems.forEach((item) => {
                            item.classList.add("u-visually-hidden");
                            item.classList.remove("u-visually-hidden-undo");
                        });
                        buttonLabel.innerHTML = "mehr anzeigen";
                    }
                });
            }
        })
    }
}

export default ToggleList;
