/**
 * All JavaScript required for mobile navigation
 *
 * @component mobileNav
 */

//helper functions
function hasClass(el, className) {
    return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

class MobileNav {

    constructor() {
        let self = this;
        self.mobilenavfunction();
    }

    whichTransitionEvent() {
        let t,
            el = document.createElement("fakeelement");

        const transitions = {
          "transition"      : "transitionend",
          "OTransition"     : "oTransitionEnd",
          "MozTransition"   : "transitionend",
          "WebkitTransition": "webkitTransitionEnd"
        }

        for (t in transitions){
            if (el.style[t] !== undefined){
                return transitions[t];
            }
        }
        return null;
    }

    mobilenavfunction() {
        const menuButton = document.getElementById('menuOpen');
        let isSlidedIn = false;
        let cancelReset = false;
        let transitionEvent = this.whichTransitionEvent();

        const prepareForSlideIn = function(){
            //console.log('prepare for slide in');
            menuButton.classList.add('is-outside');
            menuButton.classList.remove('is-sticky');
        }

        const slideIn = function(){
            //console.log('slide in');
            isSlidedIn = true;
            cancelReset = true;
            menuButton.classList.remove('is-outside');
            menuButton.classList.remove('is-slidingout');
            menuButton.classList.add('is-sticky');
        }

        const slideOut = function(){
            //console.log('slide out');
            menuButton.classList.remove('is-sticky');
            menuButton.classList.add('is-slidingout');

            cancelReset = false;
            if (transitionEvent !== null) {
                menuButton.addEventListener(transitionEvent, reset);
            }
        }

        const reset = function(){
            menuButton.removeEventListener(transitionEvent, reset);
            if (cancelReset === true) return;
            //console.log('reset');
            isSlidedIn = false;
            menuButton.classList.remove('is-slidingout');
            menuButton.classList.remove('is-sticky');
            menuButton.classList.remove('is-outside');
        }

        const openMenu = function () {
            if (!hasClass(overlay, 'is-open')) {
                addClass(overlay, 'c-mobile-nav--initialized');
                addClass(overlay, 'is-open');

                openButton.setAttribute('aria-expanded', 'true');
                overlay.setAttribute('aria-hidden', 'false');
                addClass(document.documentElement, 'u-noscroll');
            }
        };

        const closeMenu = function () {
            if (hasClass(overlay, 'is-open')) {
                removeClass(overlay, 'is-open');
                openButton.setAttribute('aria-expanded', 'false');
                overlay.setAttribute('aria-hidden', 'true');

                removeClass(document.documentElement, 'u-noscroll');

                if (transitionEvent !== null) {
                    addClass(overlay, 'is-close');
                    overlay.addEventListener(transitionEvent, transitionEventHandler);
                }
            }
        };

        const transitionEventHandler = function (event) {
            overlay.removeEventListener(transitionEvent, transitionEventHandler);
            removeClass(overlay, 'is-close');
        }

        // 'ESC' key handling for menu
        const closeOnEsc = function (e) {
            if (hasClass(overlay, 'is-open') && e.keyCode === 27) {
                closeMenu();
            }
        };

        // requestAnimationFrame = request that the browser calls a specified function
        // to update an animation before the next repaint
        const requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function(f){return setTimeout(f, 1000/60)};

        // add scroll handler with usage of requestAnimationFrame
        window.addEventListener('scroll', function() {
            // do not run for viewports wider than 1000px
            const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (w > 1000) return;

            if (window.pageYOffset < 200 ) {
                requestAnimationFrame(reset);

            } else if (window.pageYOffset >= 200 && this.window.pageYOffset < 400) {
                if (isSlidedIn) {
                    requestAnimationFrame(slideOut);
                } else {
                    requestAnimationFrame(prepareForSlideIn);
                }

            } else if (window.pageYOffset >= 400) {
                requestAnimationFrame(slideIn);
            }
        });

        // handling initial state
        if (window.pageYOffset >= 400) {
            requestAnimationFrame(slideIn);
        }

        let openButton, closeButton, overlay;
        openButton = document.getElementById('menuOpen');
        closeButton = document.getElementById('menuClose');
        overlay = document.getElementById('menu');
        openButton.addEventListener('click', openMenu);
        closeButton.addEventListener('click', closeMenu);
        document.addEventListener('keydown', closeOnEsc);
    }
}

export default MobileNav;
